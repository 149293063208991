import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';
import Row, { Col } from '../../../components/Elements/Grid';

import Image from '../../../components/Elements/Image';
import SignUp from '../../../components/Parts/SignUp';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('managedHostingSecurityCompliance');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerSecurityCompliance"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center top' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('title')}</h1>
            <p>{t('intro.p1')}</p>
            <p>{t('intro.p2')}</p>
          </PageSection>
          <PageSection grid slide>
            <Row>
              <Col>
                <Image image="pagesSecurityComplianceCertificates" />
              </Col>
              <Col>
                <h2 className="text-center">{t('certifications.title')}</h2>
                <Row>
                  <Col>
                    <p>{t('certifications.description.p1')}</p>
                    <p>{t('certifications.description.p2')}</p>
                    <p>{t('certifications.description.p3')}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>{t('iso9001.title')}</h3>
                <p>{t('iso9001.description')}</p>
              </Col>
              <Col>
                <h3>{t('iso27001.title')}</h3>
                <p>{t('iso27001.description')}</p>
              </Col>
              <Col>
                <h3>{t('nen7510.title')}</h3>
                <p>{t('nen7510.description')}</p>
              </Col>
            </Row>
          </PageSection>
          <SignUp />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
